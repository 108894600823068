<template>
  <div>
    <MasterHeader />
    <sign-up-modal v-if="hasNewSignupFlow"/>
    <div v-if="!isLoaded" class="loader">Loading...</div>
    <div class="container container-wide" v-if="isLoaded">
      <div class="row">
        <div class="widget-splash splash-line"></div>
        <div class="col-12">
          <div class="widget">
            <div class="widget-splash splash-line"></div>
            <div class="widget-header">
              <h4>Events list</h4>
            </div>
            <div class="widget-body widget-suggestion">
              <p class="event_headers">Ongoing event</p>
              <router-link v-for="event in event.events.ongoingevent" :key="event.event_id" :to="'/events/ongoing/' + event.event_id" ><img class="event-image" :src="event.event_logo" alt="" srcset=""></router-link>
              <p class="event_headers">Future event</p>
              <router-link v-for="event in event.events.futureevent" :key="event.event_id" :to="'/events/future/' + event.event_id" ><img class="event-image" :src="event.event_logo" alt="" srcset=""></router-link>
              <p class="event_headers">Past event</p>
              <router-link v-for="event in event.events.pastevent" :key="event.event_id" :to="'/events/past/' + event.event_id" ><img class="event-image" :src="event.event_logo" alt="" srcset=""></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MasterHeader from '@/components/MasterHeader.vue';
import { mapState } from 'vuex';
import SignUpModal from '../../components/signUpModal.vue';

export default {
  components: {
    MasterHeader,
    SignUpModal,
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  mounted() {
    if (!this.event.events) {
      this.$store.dispatch('getEvents').then(() => this.isLoaded = true)
    }else {
      this.isLoaded = true
    }
  },
  computed: {
    ...mapState(['user', 'event']),
        hasNewSignupFlow(){
      const vm = this
      const isValid = vm.$store.getters.currentProgress >= 0 ? true : false
      const isStudent = vm.$store.getters.role === 'Student'
        if (!isValid && isStudent) {
          localStorage.setItem('notSignedUp', 1);
        }
        if (isValid) {
          localStorage.removeItem('notSignedUp');
        }
      return true
    },
  },
};
</script>
<style scoped>
.thesis-body {
  padding: 2rem;
  background: #fff;
  -webkit-box-shadow: 0 1px 4px rgb(0 0 0 / 15%);
  box-shadow: 0 1px 4px rgb(0 0 0 / 15%);
  border-radius: 4px;
  margin: 0;
}
.text-bold {
  font-weight: 700;
}
.thesis-button {
  list-style: none;
  border: 1px solid #da518a;
  padding: 0.2rem 0.5rem;
  border-radius: 3px;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  color: #da518a;
  font-size: 14px;
  background: transparent;
}
table tr th:not(:last-child),
td:not(:last-child) {
  border-right: 1px solid #dee2e6;
}
table tr th:not(:first-child) {
  background-color: #fcfcfc;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(219, 95, 138, 0.2);
  border-right: 1.1em solid rgba(219, 95, 138, 0.2);
  border-bottom: 1.1em solid rgba(219, 95, 138, 0.2);
  border-left: 1.1em solid #db5f8a;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.event-image{
  width: 40%;
  display: block;
  height: auto;
}
.event_headers {
  font-weight: 500;
  margin-top: 15px;
}

@media (max-width: 499px) {
  .event-image {
    width: 100%
  }
}
</style>
